import React, { PureComponent } from 'react';
import { withCookies } from 'react-cookie';
import { frontloadConnect } from 'react-frontload';
import { connect } from 'react-redux';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import { PortalConfigContext, getConfig } from '../../config/portal';
import ErrorMessage from '../../components/ErrorMessage';
import DefaultLayout from '../../layouts/Default';
import * as utils from '../../store/utils';
import * as actions from '../../store/actions';

import NotFoundPage from '../NotFoundPage';
import MainEditorialContent from './MainEditorialContent';
import { Redirect } from 'react-router-dom';

import {getSubdomain, removeEnvironmentSubdomain} from '../../tppServices/translations/helpers';
import {getDefaultI18Service} from '../../tppServices/tppDi';

class StaticContent extends PureComponent {
  renderEditorialLayout() {
    return this.props.errors ? (
      <NotFoundPage {...this.props} />
    ) : (
      <DefaultLayout {...this.props} pageType="EditorialPage">
        <MainEditorialContent {...this.props} />
      </DefaultLayout>
    );
  }

  renderRedirect() {
    return (
      <Redirect to={{ pathname: this.props.redirectTo }} {...this.props} />
    );
  }

  render() {
    const {
      errors,
      location: { pathname },
      success,
      statusCode,
      redirectTo
    } = this.props;

    const excludedStaticPages = get(getConfig(), 'pages.excludeStaticPaths');

    // If path exists in list of excluded static pages, render NotFoundPage.
    if (excludedStaticPages && excludedStaticPages.includes(pathname)) {
      return <ErrorMessage {...this.props} debug={'from static content'}/>;
    }

    // Render editorial layout on data acquisition
    if ((errors && statusCode < 500) || success) {
      if (redirectTo) {
        return this.renderRedirect();
      }
      return this.renderEditorialLayout();
    }
    return <ErrorMessage {...this.props} debug={'from static 2'}/>;
  }
}

const mapStateToProps = (state) => {
  return {
    params: get(state.app, 'params', {}),
    articles: get(state.app, 'data', {}),
    success: state.app.success,
    errors: state.app.errors,
    statusCode: state.app.statusCode,
    redirectTo: get(state.app.data, 'redirectTo', undefined)
  };
};

StaticContent.propTypes = {
  errors: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired,
  success: PropTypes.bool,
  statusCode: PropTypes.number,
  redirectTo: PropTypes.string
};

StaticContent.contextType = PortalConfigContext;

export default withCookies(
  connect(
    mapStateToProps,
    null
  )(
    frontloadConnect(
      ({ dispatch, location, cookies }) => {
        const defaultI18Service = getDefaultI18Service();
        const getCurrentHost = defaultI18Service.getCurrentHost;
        const currentHost = removeEnvironmentSubdomain(getCurrentHost());
        const currentSubdomain = getSubdomain(currentHost);

        const lookupRedirects = get(
          getConfig().supports,
          'lookupGenericRedirects',
          false
        );
        if (utils.isServer()) {
          return dispatch(
            lookupRedirects
              ? actions.getRedirectAndEditorialContent(
                  location.pathname,
                  actions.getRedirectData(
                    location.pathname,
                    location.search,
                    currentSubdomain
                  )
                )
              : actions.getEditorialContent(location.pathname, cookies.cookies)
          );
        }
        if (actions.shouldGetData(location)) {
          return dispatch(
            lookupRedirects
              ? actions.getRedirectAndEditorialContent(
                  location.pathname,
                  actions.getRedirectData(
                    location.pathname,
                    location.search,
                    currentSubdomain
                  )
                )
              : actions.getEditorialContent(location.pathname, cookies.cookies)
          );
        }
      },
      {
        onUpdate: true,
        onMount: true
      }
    )(StaticContent)
  )
);
