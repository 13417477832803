import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';

import EditorialItem from '../../../components/EditorialItem';
import { PortalConfigContext } from '../../../config/portal';

import './styles.css';

class MainEditorialContent extends PureComponent {
  render() {
    const { articles } = this.props;
    return (
      <EditorialItem
        content={articles.records?.[0]?.content?.body}
        title={articles.records?.[0]?.title}
        classOverride={'article'}
      />
    );
  }
}

MainEditorialContent.propTypes = {
  /* Article element to be rendered */
  articles: PropTypes.object
};

MainEditorialContent.contextType = PortalConfigContext;

export default connect(null, (dispatch) => bindActionCreators({}, dispatch))(
  injectIntl(MainEditorialContent)
);
